$scaffold-color: #dfe1e6;

@mixin sequence-animation {
  @for $i from 1 through 12 {
    &:nth-child(#{$i}) {
      animation-delay: $i * (1s / 24); } } }

.slider {
  padding-left: 16px;

  .slide {
    padding: 16px;
    padding-top: 26px;
    width: 100% !important;
    min-height: 300px;
    display: flex !important;
    justify-content: space-between;
    align-items: space-between;
    flex-direction: column;

    &__list {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      list-style: none;
      flex-direction: row;
      flex-wrap: wrap;
      color: #fff;

      li {
        padding: 0;
        margin: 0;
        margin-right: 8px;
        font-size: 1.2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        &.has-circle {
          background-color: #F2F4F7;
          border-radius: 50%;
          width: 90px;
          height: 90px;
          color: #916CCE; } } }

    h3 {
      font-size: 2rem !important;
      color: #FFF; } } }

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.6);
  color: #A0A3AF;

  &::after {
    content: "Carregando..."; } }

.main-container {
  max-width: 95%;
  margin: 0 auto;
  color: #A0A3AE; }

.description {
  font-size: 1.1rem;
  font-family: "Roboto";
  position: absolute;
  top: -30px;
  left: 2px; }

.payment-account-info {
  display: flex;
  justify-content: space-around;
  margin-top: 32px;

  @media screen and (max-width: 922px) {
    flex-wrap: wrap;
    & > * {
      width: 100% !important; } }

  .payment-account-details {
    .redirect-to-payment-account {
      position: absolute;
      right: 0;
      top: 0;

      button {
        appearance: none;
        border: none;
        outline: none;
        cursor: pointer;
        background: transparent; } }


    &__name {
      color: #666666;
      font-family: "Roboto";
      font-size: 2rem; }

    &__payment-method {
      font-family: "Roboto";
      margin-bottom: 56px; }

    &__company {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      border-radius: 15px 15px 4px 4px;
      font-family: "Roboto";
      color: #916CCF;
      display: flex;
      flex-direction: column;

      .label {
        font-size: .7rem;
        margin-left: 15px;
        display: block;
        border-bottom: 1px solid #916CCF;
        margin-right: 15px; }

      div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px;
        padding-top: 4px;
        color: #666666; } } }

  & > * {
    padding: 16px;
    width: 50%;
    background: #FFFFFF;
    &:nth-child(1) {
      padding: 0;
      background: #11cf99;
      &:not {
        .description {
          color: #FFFFFF; } } }
    margin: 16px;
    border-radius: 4px;
    box-shadow: 0 10px 15px 1px rgba(0, 0, 0, 0.15);
    min-height: 250px;
    position: relative; } }

.cost-centers {
  margin: 0 auto;
  margin-top: 26px;
  position: relative;

  .description {
    left: 1%;
    top: -25px; } }

.cost-centers-cards {
  margin: -15px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); }

.cost-center-card {
  display: inline-block;
  margin: 12px;
  padding: 26px;
  background-color: #FFF;
  box-shadow: 0 10px 15px 1px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  text-align: left;
  color: #1e2328;

  animation: .3s ease-in-out both appear;
  @include sequence-animation;

  &__name {
    font-size: 1rem;
    font-family: "Roboto";
    font-weight: bold;
    margin-bottom: 10px; }

  &__creation {
    font-size: .8rem;
    font-family: Arial;
    word-wrap: break;
    max-width: 80%;
    line-height: 1.1;
    opacity: .7; }

  &__details {
    display: block;
    text-align: right;

    button {
      font-family: "Roboto";
      outline: none;
      appearance: none;
      margin-top: 32px;
      padding: 8px;
      background-color: #653D98;
      border: none;
      border-radius: 5px;
      color: #FFFFFF;
      cursor: pointer;

      transition: transform .2s ease-out, box-shadow .15s ease-in-out;

      &:hover {
        transform: translateY(-1.5px);
        box-shadow: 0 5px 12px rgba(0, 0, 0, 0.15); } } } }

.credit-card {
  color: #FFF;
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 350px;
  margin: 8px auto;
  box-shadow: 0 5px 12px rgba(0, 0, 0, 0.35);
  border-radius: 15px;
  font-family: "Roboto";
  background: linear-gradient(25deg, #0f509e, #1399cd);

  & > * {
    min-height: 28px; }

  &__logo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 16px 12px;
    border-radius: 15px 15px 0 0; }

  &__number {
    display: flex;
    align-items: center;
    background: #242D62;
    padding: 16px 12px; }

  &__info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 12px;
    border-radius: 0 0 15px 15px;
    font-size: .8rem;

    & > * {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      max-width: 120px; } } }

.scaffold {
  display: inline-block;
  margin: 12px;
  padding: 24px 12px 16px 16px;
  box-shadow: 0 10px 15px 1px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
  min-height: 185px;

  animation: 1s ease 0s pulse infinite;
  @include sequence-animation; }


@keyframes appear {
  0% {
    opacity: 0;
    transform: scale(0); }
  50% {
    opacity: 1;
    transform: scale(1.015); }
  100% {
    opacity: 1;
    transform: scale(1); } }

@keyframes pulse {
  0% {
    background: $scaffold-color;
    transform: scale(1); }
  25% {
    background: darken($scaffold-color, 20%);
    transform: scale(1.015); }
  100% {
    background: #A0A3AE;
    transform: scale(1); } }
